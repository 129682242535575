export default {
  views: {
    login: {
      title: 'Welcome to Sagano Train'
    },
    select: {
      title: '列車情報選択',
      next: '次へ',
      prev: '戻る',
      trainPlaceholder: '列車を選択してください',
      setButton: '入退場設定'
    },
    set: {
      title: '入退場設定',
      up: '上り（出場の設定）',
      down: '下り（入場の設定）',
      saganoStation: '嵯峨駅',
      arashiyamaStation: '嵐山駅',
      screenOn: 'ON',
      screenOff: 'OFF',
      updated: '{{name}}を更新しました。',
      form: {
        trainNumber: '改札する号車',
        train: '改札する列車',
        screen: 'スクリーン設定',
        station: '対応駅',
        trainToFour: '1〜4号車',
        trainToFive: '1〜5号車'
      }
    },
    scan: {
      title: 'チケットを読み取ります',
      manual: '予約番号を手動で入力する',
      manualPlaceholder: '予約番号を入力してください',
      prev: '戻る',
      confirm: '確認',
      result: {
        success: {
          title: '乗車できました',
          date: '乗車日：',
          direction: '行き先：',
          time: '乗車時間：',
          seat: '座席：',
          unit: '人数：'
        },
        fail: {
          title: '乗車になれません',
          errorMsg: {
            BOOKING_NOT_FOUND: '予約が見つかりません',
            ALREADY_REDEEMED: 'すでに読み取られています。',
            DATE_MISMATCH: '予約日が異なります。',
            SERVICE_MISMATCH: '予約便（時間）が異なります。',
            STATION_MISSING: '駅が見つかりません',
            OUT_OF_SERVICE: '予約便が運休になりました。',
            CANCELLED: 'すでにキャンセルされた乗車券です。'
          }
        },
        code: '予約番号：{{code}}',
        sellDate: '販売日時：{{date}}',
        redeemDate: '読み取り時間：{{date}}'
      }
    }
  },
  carName: {
    car1: '1号車',
    car2: '2号車',
    car3: '3号車',
    car4: '4号車',
    car5: 'リッチ号/5号車',
    directionUpTitle: '↑ トロッコ嵯峨方面',
    directionDownTitle: '↑ トロッコ亀岡方面'
  },
  seat: {
    seat: '座席',
    standing: '立見席',
    seatInfo: '残り{{num}}人'
  },
  dialog: {
    title: '予約済みの席を確認',
    bookingId: '予約番号',
    date: '乗車日',
    station: '行き先',
    time: '時間',
    channel: '購入区分'
  },
  bookingChannel: {
    BOOKING_CHANNEL_WEB: 'web',
    BOOKING_CHANNEL_SAGA: '嵯峨',
    BOOKING_CHANNEL_ARASHIYAMA: '嵐山',
    BOOKING_CHANNEL_KAMEOKA: '亀岡',
    BOOKING_CHANNEL_SAGA_COLLECTED: '嵯峨集',
    BOOKING_CHANNEL_ARASHIYAMA_COLLECTED: '嵐山集',
    BOOKING_CHANNEL_KAMEOKA_COLLECTED: '亀岡集',
    BOOKING_CHANNEL_CONDUCTOR: '車掌',
    BOOKING_CHANNEL_CONDUCTOR_COLLECTED: '車掌集'
  }
};
