import React from 'react';
import auth, { Auth } from './modules/auth';
import stations, { Stations } from './modules/stations';
export type RootStore = {
  auth: Auth;
  stations: Stations;
};

export const store: RootStore = {
  auth,
  stations
};

const StoreContext = React.createContext<RootStore>(store);

export const StoreProvider = StoreContext.Provider;

export const useStore = () => React.useContext(StoreContext);

export { auth };
